<template>
  <el-input
            :class="[
                'ys-textarea',
                {
                  'is-dark': dark,
                  'is-disabled': disabled,
                  'is-hide-background': !showBackground,
                  'is-focused': focused,
                },
                innerClass,
                ]"
            ref="textarea"
            v-bind="$attrs"
            :tabindex="tabindex"
            type="textarea"
            :show-password="showPassword"
            :disabled="disabled"
            :readonly="readonly"
            :auto-complete="autocomplete"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
            :label="label"
            v-model="value"
            :autosize="autosize"
            :placeholder="placeholder"
            :rows="rows"
            @keydown.enter.native="handleEnterDown"
  ></el-input>
</template>

<script>
export default {
  name: "YsTextarea",
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'normal',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [String, Number],
    resize: String,
    form: String,
    readonly: Boolean,
    rows: Number,
    autosize: {
      type: [Boolean, Object],
      default() {
        return {
          minRows: 2,
          maxRows: 50,
        }
      },
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    suffixIcon: String,
    prefixIcon: String,
    label: String,
    clearable: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
    tabindex: String,
    placeholder: String,
    innerClass: String,
    keyEnter: Function,
  },
  data() {
    return {
      focused: false,
    }
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleChange(value) {
      this.$emit('change', value);
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    focus() {
      this.$refs.textarea.focus();
    },
    handleEnterDown: function (e) {
      if(this.keyEnter) {
        e.returnValue = false;
        this.keyEnter(e);
        return false;
      }
    }
  }
}
</script>

<style scoped>

.ys-textarea {
  border-radius: 8px;
  background-color: white;
  padding: 8px;
  box-sizing: border-box;
}

.ys-textarea.is-focused {
  border: 2px solid #f091a6;
  box-shadow: 0 0 8px #f091a6 !important;
}

.ys-textarea.is-dark {
  background-color: #f1f1f1;
}

ys-textarea.is-disabled {
  opacity: 0.5;
}

.ys-textarea.is-hide-background,  .ys-textarea.is-dark.is-hide-background, .ys-textarea.is-focused.is-hide-background {
  box-shadow: none !important;
  background-color: transparent !important;
  border: none;
}

.ys-textarea>>>.el-textarea__inner {
  background-color: transparent;
  border: none;
  resize: none;
  font: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-align: justify;
}

.ys-textarea>>>.el-textarea__inner::-webkit-scrollbar {
  visibility: hidden;
}

</style>