import { render, staticRenderFns } from "./YsTextarea.vue?vue&type=template&id=96071f06&scoped=true&"
import script from "./YsTextarea.vue?vue&type=script&lang=js&"
export * from "./YsTextarea.vue?vue&type=script&lang=js&"
import style0 from "./YsTextarea.vue?vue&type=style&index=0&id=96071f06&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96071f06",
  null
  
)

export default component.exports