function isBlank(str) {
    if(!str) {
        return true;
    } else {
        return /^\s+$/.test(str);
    }
}

const TextUtils = {
    isBlank: isBlank,
}

export default TextUtils;