<template>
  <div>
    <el-image-viewer ref="imageViewer" class="image-viewer" v-show="isVisible" :url-list="imageUrlList" :initial-index="currentImageIndex"
      :on-close="handleImageDbClick">
    </el-image-viewer>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "ys-image-preview",
  mixins: [httpapi],
  components: {
    ElImageViewer
  },
  computed: {
    imageUrlList() {
      return this.imageList.map(x => this.$prepareImageUrl(x));
    }
  },
  props: {
    imageList: {
      type: Array,
      default() {
        return [];
      }
    },
    currentImageIndex: Number,
    visible: Boolean,
  },
  data() {
    return {
      isVisible: false,
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    }
  },
  methods: {
    handleImageDbClick() {
      this.isVisible = false;
      this.$emit('update:visible', this.isVisible);
    },
  },
  mounted() {
    //this.$refs.imageViewer.$el.querySelector('.el-image-viewer__canvas').addEventListener('dblclick', this.handleImageDbClick);
    //this.$refs.imageViewer.$refs.img.$el.addEventListener('dblclick', this.handleImageDbClick);
  },
  destroyed() {
    //this.$refs.imageViewer.$el.querySelector('.el-image-viewer__canvas').removeEventListener('dblclick', this.handleImageDbClick);
    //this.$refs.imageViewer.$refs.img.$el.removeEventListener('dblclick', this.handleImageDbClick)
  }
}
</script>

<style scoped>

.image-viewer {
  backdrop-filter: blur(2px);
}
</style>