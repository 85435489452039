var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-input',_vm._b({ref:"textarea",class:[
              'ys-textarea',
              {
                'is-dark': _vm.dark,
                'is-disabled': _vm.disabled,
                'is-hide-background': !_vm.showBackground,
                'is-focused': _vm.focused,
              },
              _vm.innerClass ],attrs:{"tabindex":_vm.tabindex,"type":"textarea","show-password":_vm.showPassword,"disabled":_vm.disabled,"readonly":_vm.readonly,"auto-complete":_vm.autocomplete,"label":_vm.label,"autosize":_vm.autosize,"placeholder":_vm.placeholder,"rows":_vm.rows},on:{"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnterDown($event)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'el-input',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }